<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>date_range</md-icon>
            <h3 class="pl-2 text-uppercase">Schedule Live</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <SelectComponent class="w-64 mx-3 mb-10" />
        <Calendar />
      </CardBody>
    </Card>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent } from "@/components";
import Calendar from "@/components/atom/Calendar";
import { mapMutations } from "vuex";

export default {
  name: "Blog-List-View",
  components: {
    Card,
    CardBody,
    SelectComponent,
    Calendar,
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
  },
};
</script>
